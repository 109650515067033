import { RouteNames } from './../../../router/route-names';
import { RoleTypes } from "@/core/enums/role-types";
import { encryptStorage } from "@/core/plugins/encryptStorage";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import RolesModuleState from "./models/RolesModuleState";
import routesRoles from './constans/permition-routes-roles';
enum Actions {
    SET_ROLES_USER = "SET_ROLES_USER"
    , GET_PERMISSION_MODULE = "GET_PERMISSION_MODUÑE"
}
enum Mutations {
    SET_ROLES = "SET_ROLES"
}

@Module
export default class RolesModule extends VuexModule implements RolesModuleState {
    roles = encryptStorage.getItem<string[]>("roles") ?? [];

    /**
     * Permisos para los ver las rutas
     */
    get canSeeRoute(): (routeName: RouteNames) => boolean { 
        return (routeName: RouteNames) => {
            return  canSeeRoute( routeName, this.roles);
        }
    }

    /** Permisos módulo Órdenes de Compra. INICIO */
    get canEditPOAutorized(): boolean {
        return  canDoit([
            RoleTypes.Administrator,
            //RoleTypes.AdminStore
        ], this.roles);
    }

    get canApplyCI(): boolean {
        return  canDoit([
            RoleTypes.PurchasingManager,
            RoleTypes.AdminStore
        ], this.roles);
    }

    get canApplyCF(): boolean {
        return  canDoit([
            RoleTypes.PurchasingManager,
            RoleTypes.AdminStore
        ], this.roles);
    }

    get canApplyAA(): boolean {
        return  canDoit([
            RoleTypes.AdminPurchasing
        ], this.roles);
    }
    /** Permisos módulo Órdenes de Compra. INICIO */

    /**
     * FIN
     */
    /**Permisos Modulo Caja INICIA*/

    /**
     * Indica se el ususario puede Pagar Solicitudes de Caja
     */
    get canPaymentRequest(): boolean {
        return  canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminCashRegister
    ], this.roles);
    }

    /**
     * Indica se el ususario puede Cancelar Solicitudes de Caja
     */
    get canCancelRequest(): boolean {
        return  canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminCashRegister
    ], this.roles);
    }
    /**Permisos Modulo Caja TERMINA*/

    get showItemMenu(): (rolesPermission: RoleTypes[] ) => boolean  {
        return (rolesPermission: RoleTypes[]) => {
            return  canDoit( rolesPermission, this.roles);
        }
    }

    /**
     * Indica si debe mostrar el catalogo de familia
     */
     get showFamilies(): boolean {
        return canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminStore,
        ], this.roles);
    }

    /**
     * Indica si debe mostrar el catalogo de subfamilia
     */
    get showSubFamilies(): boolean {
        return canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminStore,
        ], this.roles);
    }

    /**
     * Indica si debe mostrar el catalogo unidad de medida
     */
    get showMeasurementUnit(): boolean {
        return canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminStore,
        ], this.roles);
    }

    /**
     * Indica si debe mostrar el catalogo tipo de pago
     */
    get showTypePayment(): boolean {
        return canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminStore,
        ], this.roles);
    }   

    /**
     * Indica si debe mostrar el catologo de linea
     */
    get showCatalogLine(): boolean {
        return canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminStore,
        ], this.roles);
    }

    /**
     * Indica si debe mostrar la pagina del panel
     */
    get showDasboardStadistic(): boolean {
        return canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminReports
        ], this.roles);
    }

     /**
     * Indica si debe mostrar la pagina de caja
     */
      get showCashregister(): boolean {
        return  canDoit([
            //aqui puedes agregar mas roles que permitan la acción del get
            RoleTypes.AdminCashRegister,
    ], this.roles);
    }

    get canSalePriceFree(): boolean {
        return canDoit([
            RoleTypes.Administrator, RoleTypes.AdminCashRegister 
        ], this.roles);
    }
    get canAutorizatePhysicalCount(): boolean {
        return canDoit([], this.roles);
    }
     
     @Mutation
    [Mutations.SET_ROLES](roles: string[]){
        this.roles = roles;
    }

     @Action
     [Actions.SET_ROLES_USER](roles: string[]){
        encryptStorage.setItem("roles", roles)
        this.context.commit(Mutations.SET_ROLES, roles);
     }
}

/**
 * Indica si se puede hacer la acción segun los roles permitidos y los roles de usuario 
 * (por defecto si es administrador puede hacer todo)
 * @param allowedRoles tipos de roles que pueden hacer la acción
 * @param userRoles roles del usuario
 * @returns indica si el usuario contiene el rol
 */
const canDoit = (allowedRoles: RoleTypes[], userRoles: string[]): boolean => {
    allowedRoles.push(RoleTypes.Administrator);//con esto el administrador puede hacer todas las accioones
    return  allowedRoles.some((type: string) => userRoles.map(u => u.trim()).includes(type))
}

/**
 *Indicamos que role puede ver ciertas rutas
 */
const canSeeRoute = (routeName: RouteNames, userRoles: string[]): boolean => { 
    const rolesByRoute = routesRoles.filter(f => f.availableRoutes.includes(routeName)).map(m => m.role);
    return canDoit(rolesByRoute, userRoles);

    /*switch (routeName) {
        case RouteNames.SubFamilies:
        case RouteNames.Families:
        case RouteNames.Inputs://RoleTypes.AdminStore
        case RouteNames.Outputs://RoleTypes.AdminStore
        case RouteNames.WareHouse://RoleTypes.AdminStore
        case RouteNames.Transfers://RoleTypes.AdminStore
            return canDoit([
                RoleTypes.AdminStore
            ], userRoles)
        case RouteNames.Clients:
            return canDoit([RoleTypes.Seller, RoleTypes.Cashier], userRoles);
        case RouteNames.SalesQuote:
            return canDoit([RoleTypes.Seller], userRoles);
        case RouteNames.Deliveries:
            return canDoit([RoleTypes.Seller], userRoles);
        case RouteNames.RetireCash:
            return canDoit([RoleTypes.Cashier, RoleTypes.AdminCashRegister], userRoles);
        case RouteNames.AdditionalFund:
            return canDoit([RoleTypes.Cashier, RoleTypes.AdminCashRegister], userRoles);
        case RouteNames.OpenCash:
            return canDoit([RoleTypes.Cashier, RoleTypes.AdminCashRegister], userRoles);
        case RouteNames.AdminSalesPay:
            return canDoit([RoleTypes.Cashier, RoleTypes.AdminCashRegister], userRoles);
        case RouteNames.RetireCash:
        case RouteNames.AdditionalFund:
        case RouteNames.OpenCash:
        case RouteNames.CloseCash:
            return canDoit([RoleTypes.Cashier], userRoles);
        case RouteNames.CashMovements:
            return canDoit([RoleTypes.AdminCashRegister], userRoles);
        case RouteNames.Cashiers:
        case RouteNames.CashRegisters:
            return canDoit([RoleTypes.Cashier], userRoles);
        case RouteNames.Sales:
        case RouteNames.SalesDetail:
        case RouteNames.SalesQuote:
        case RouteNames.SalesReturns:
            return canDoit([RoleTypes.Seller], userRoles);
        case RouteNames.MovementType:
            return canDoit([RoleTypes.Storer, RoleTypes.AdminStore], userRoles);
        case RouteNames.PurchaseOrders:
            return canDoit([RoleTypes.Administrator, RoleTypes.AdminPurchasing], userRoles)
            case RouteNames.Receptions:
                return canDoit([RoleTypes.Administrator, RoleTypes.AdminStore], userRoles)
        case RouteNames.Users:
        case RouteNames.AllCatalogs:
        case RouteNames.Dashboard:
        default:
            return canDoit([], userRoles);
    }*/
}


export { Actions };