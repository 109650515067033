import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
import HeadingPages from "@/core/config/interfaces/HeadingPages";
import { RouteNames } from "@/router/route-names";

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
  menuConfig: HeadingPages[];
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config = layoutConfig;
  initial = layoutConfig;
  menuConfig = [] as HeadingPages[];


  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }
  get getMenuConfig(): HeadingPages[] {
    return this.menuConfig;
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG](payload): void {
    this.config = payload;
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG]() {
    this.config = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem("config") || "{}")
    );
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
    this.config = merge(this.config, payload);
  }
  @Mutation
  [Mutations.SET_MENUCONFIG](menuConfig: HeadingPages[]): void {
    this.menuConfig = menuConfig;
  }
  @Action
  [Actions.RESET_MENUCONFIG](){
    const menu = [
      {
        pages:[
          {
            heading: "Panel"
            , route: '/dashboard'
            , fontIcon: "bi-bar-chart-line-fill"
            , canAccess:  this.context.getters['canSeeRoute'](RouteNames.Dashboard)
          }
        ]
      },
      {
        heading: "TITLES.MENU.MODULS", //NOMBRE DEL CODIGO EN EL TRASLATE
        route: "/moduls",
        pages: [
          {
            //Usuarios
            heading: "Usuarios",
            route: "/users",
            routeName: RouteNames.Users,
            svgIcon: "media/icons/duotune/communication/com014.svg",
            fontIcon: "bi-people-fill",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.Users)
          }
          ,{
            sectionTitle: "Ventas",
            route: "",
            svgIcon: "media/icons/duotune/general/gen025.svg",
             canAccess: this.context.getters['canSeeRoute'](RouteNames.SalesModule),
            fontIcon: "bi-layers",
            sub: [
              {
                heading: "Admin. Ventas",
                route: "/sales",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Sales)
              },
              {
                heading: "Cotizaciones",
                route: "/salesQuote",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.SalesQuote)
              },
              {
                heading: "Prog. Entregas",
                route: "/deliveriesViewTable",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Deliveries)
              },
              {
                heading: "Devoluciones",
                route: "/salesReturns",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.SalesReturns)
              }
            ]
          },
          {
            sectionTitle: "Caja",
            route: "",
            svgIcon: "media/icons/duotune/general/gen025.svg",
            canAccess:  this.context.getters['canSeeRoute'](RouteNames.CashRegister),
            fontIcon: "bi bi-currency-dollar",
            sub: [
              {
                heading: "Cobros",
                route: "/adminSalesPay",
                svgIcon: "media/icons/duotune/finace/fin007.svg",
                fontIcon: "bi bi-currency-dollar",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.AdminSalesPay)
              },
              {
                heading: "Apertura de Caja",
                route: "/OpenCash",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.OpenCash)
              },
              {
                heading: "Fondo Adicional",
                route: "/AdditionalFund",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.AdditionalFund)
              },
              {
                heading: "Retiro de caja",
                route: "/RetireCash",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.RetireCash)
              },
              {
                heading: "Cierre de Caja",
                route: "/CloseCash",
                svgIcon: "media/icons/duotune/finace/fin007.svg",
                fontIcon: "bi bi-currency-dollar",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.CloseCash)
              },
              {
                heading: "Movimientos de Caja",
                route: "/CashMovements",
                svgIcon: "media/icons/duotune/communication/com002.svg",
                fontIcon: "bi bi-cash-coin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.CashMovements)
              },
              {
                heading: "Fras. Creadas",
                route: "/billing/created",
                fontIcon: "bi-card-checklist",
                canAccess: true
              },
            ]
          },
          // {
          //   //Caja
          //   heading: "Caja",
          //   route: "/adminSalesPay",
          //   svgIcon: "media/icons/duotune/finace/fin007.svg",
          //   fontIcon: "bi bi-currency-dollar",
          //   canAccess: true
          // },
          {
            //COMPRAS
            sectionTitle: "Compras",
            route: "/shopping",
            svgIcon: "media/icons/duotune/finace/fin007.svg",
             canAccess: this.context.getters['canSeeRoute'](RouteNames.ShoppingModule),
            fontIcon: "bi bi-cart-plus-fill",
            sub: [
              {
                heading: "Órdenes de Compras",
                route: "/shopping/adminPurchaseOrders",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.PurchaseOrders)
              }
              ,{
                heading: "Recepciones",
                route: "/shopping/receptions",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Receptions)
              }
            ]
          },
          {
            //ALMACENES
            sectionTitle: "Almacén",
            route: "/warehouse",
            svgIcon: "media/icons/duotune/arrows/abs027.svg",
            fontIcon: "bi-boxes",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.WareHouse),
            sub: [
               {
                heading: "Admin. Almacén",
                route: "/warehouseadmin",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Inputs)
              },
              {
                heading: "Entradas",
                route: "/warehouse/adminInputs",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Inputs)
              },
              {
                heading: "Salidas",
                route: "/warehouse/adminOutputs",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Outputs)
              },
              {
                heading: "Traspasos",
                route: "/warehouse/transfers",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Transfers)
              }
            ]
          },
          // {
          //   //Usuarios
          //   heading: "Ventas",
          //   route: "/sales",
          //   svgIcon: "media/icons/duotune/communication/com002.svg",
          //   fontIcon: "bi bi-cash-coin",
          //   canAccess: true
          // },
          {
            heading: "Clientes",
            fontIcon:"bi bi-people-fill",
            routeName: RouteNames.Clients,
            route: "/clients",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.Clients)
          },
          {
            sectionTitle: "Facturación",
            route: "",
             canAccess:  this.context.getters['canSeeRoute'](RouteNames.BillingModule),
            fontIcon: "bi-receipt-cutoff",
            sub: [
              {
                heading: "Fra. por Venta",
                route: "/billing/sale",
                fontIcon: "bi-card-checklist",
                canAccess: true
              },
              {
                heading: "Fra. Global",
                route: "/billing/bill",
                fontIcon: "bi-receipt",
                canAccess: true
              },
              {
                heading: "Fras. Creadas",
                route: "/billing/created",
                fontIcon: "bi-card-checklist",
                canAccess: true
              },
              {
                heading: "Fras. Enviadas",
                route: "/billing/send",
                fontIcon: "bi-card-checklist",
                canAccess: true
              },
            ]
          },
           {
            sectionTitle: "Inventario fisico",
            route: "",
             fontIcon: "bi bi-boxes",
             canAccess: this.context.getters['canSeeRoute'](RouteNames.PhysicalInventoryModule),
            sub: [
              {
                heading: "Consulta de movimientos",
                route: "/physical-inventory/movement-preview",
                fontIcon: "bi bi-box",
                canAccess: true
              },
              {
                heading: "Conteo físico",
                route: "/physical-inventory/physical-count",
                fontIcon: "bi bi-bounding-box",
                canAccess: true
              }
              
            ]
          },
        ].sort((a, b) => (a.heading ?? '').localeCompare(b.heading ?? '')),
      }, 
      {
        heading: "Catálogos",
        route: "",
        pages: [
          {
            sectionTitle: "Almacén",
            route: "",
            svgIcon: "media/icons/duotune/general/gen025.svg",
            fontIcon: "bi-boxes",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.AllCatalogs),
            sub: [
              {
                heading: "Almacenes",
                route: "/settings/catalogs/warehouses",
                canAccess: true //this.context.getters['showSubFamilies']
              },
              {
                heading: "Artículos",
                route: "/settings/catalogs/articles",
                canAccess: true
              },
              {
                heading: "Colores",
                route: "/settings/catalogs/colors",
                canAccess: true //this.context.getters['showSubFamilies']
              },
              {
                heading: "Clases",
                route: "/settings/catalogs/subFamilies",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.SubFamilies)
              },
              {
                heading: "Familias",
                route: "/settings/catalogs/families",
                canAccess: this.context.getters['canSeeRoute'](RouteNames.Families)
              },
              {
                heading: "Impuestos",
                route: "/settings/catalogs/taxes",
                canAccess: true //this.context.getters['showSubFamilies']
              },
              {
                heading: "Lineas",
                route: "/settings/catalogs/Lines",
                canAccess: this.context.getters['showCatalogLine']
              },
              {
                heading: "Proveedores",
                route: "/settings/catalogs/census",
                canAccess: true
              },
              {
                heading: "Unidad de Medida",
                route: "/settings/catalogs/MeasurementUnits",
                canAccess: this.context.getters['showMeasurementUnit']
              }
            ].sort((a, b) => a.heading.localeCompare(b.heading))
          },
          {
            sectionTitle: "Caja",
            route: "",
            svgIcon: "media/icons/duotune/general/gen025.svg",
            fontIcon: "bi-currency-dollar",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.AllCatalogs),
            sub: [
              {
                heading: 'Cajas'
                , route: "/settings/catalogs/cash-registers"
                , canAccess: this.context.getters['canSeeRoute'](RouteNames.CashRegisters)
              },
              {
                heading: 'Cajeros'
                , route: "/settings/catalogs/cashiers"
                , canAccess: this.context.getters['canSeeRoute'](RouteNames.Cashiers)
              }
            ].sort((a, b) => a.heading.localeCompare(b.heading))
          },
          {
            sectionTitle: "Facturación",
            route: "",
            svgIcon: "media/icons/duotune/general/gen025.svg",
            fontIcon: "bi-receipt-cutoff",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.AllCatalogs),
            sub: [
              {
                heading: "Forma de pago",
                route: "/settings/catalogs/paymentType",
                canAccess: true //this.context.getters['showSubFamilies']
              },
              {
                heading: "Método de pago",
                route: "/settings/catalogs/wayToPay",
                canAccess: true //this.context.getters['showSubFamilies']
              },
              {
                heading: 'Regimen'
                , route: "/settings/catalogs/regime"
                , canAccess: true
              },
              {
                heading: "Tipo de Moneda",
                route: "/settings/catalogs/currency",
                canAccess: true //this.context.getters['showSubFamilies']
              },
              {
                heading: "Usos de C.F.D.I.",
                route: "/settings/catalogs/typesUseCfdi",
                canAccess: true //this.context.getters['showSubFamilies']
              }            
            ].sort((a, b) => a.heading.localeCompare(b.heading))
          }
        ]
      },
      {
        heading: "Reportes y Graficas",
        route: "",
        pages: [
          {
            heading: "Reportes",
            fontIcon: "bi-file-earmark-bar-graph",
            routeName: RouteNames.Report,
            route: "/reports",
            //svgIcon: "media/icons/duotune/general/gen025.svg",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.Report)
          },
          {
            heading: "Graficos",
            fontIcon: "bi-bar-chart",
            routeName: RouteNames.Report,
            route: "/graphics",
            canAccess: this.context.getters['canSeeRoute'](RouteNames.Graphics)
          }
        ]
      }
    ] as  HeadingPages[]
    this.context.commit(Mutations.SET_MENUCONFIG, menu);
  }
}
